import React,{useState} from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ActivityDropdown from './ActivityDropdown';

// function LinkTab(props) {
//   return (
//     <Tab
//       component="a"
//       onClick={(event) => {
//         event.preventDefault();
//       }}
//       {...props}
//     />
//   );
// }

export default function NavTabs() {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', fontFamily: 'Open Sans', fontWeight: 'bold', color:'#fff', fontSize: '16px' }}>
      <Tabs value={tabValue} onChange={handleChange} aria-label="nav tabs example" style={{display:'inline-flex'}}>
        <ActivityDropdown 
          setTabValue={setTabValue}
        />
          <Tab sx={{fontFamily: 'inherit', fontWeight: 'inherit', color: 'inherit', fontSize: 'inherit'}} component={Link} label="About" to="/about" />
          <Tab sx={{fontFamily: 'inherit', fontWeight: 'inherit', color: 'inherit', fontSize: 'inherit'}} component={Link} label="Collaborate" to="/contact" />
      </Tabs>
    </Box>
  );
}
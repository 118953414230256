import React from 'react';
import Portfolio from './portfolio/Portfolio';

const Home = () => {
	return(
		<>
			<div>
				<Portfolio />
			</div>
		</>
	);
}

export default Home;
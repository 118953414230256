import React,{useContext} from 'react';
import styled from 'styled-components'
import Grid from '@mui/material/Grid';
import PageContainer from '../components/PageContainer'
import { Link } from 'react-router-dom';
import {ViewingDeviceContext} from '../contexts/ViewingDeviceContext'
import {ViewingDeviceViews} from '../utils.js'
// import ImageViewer from "react-simple-image-viewer";

const ImageContainer = styled(Grid)({
	textAlign: 'initial'
});

const SectionWrapper = styled.div`
	margin: 1.5rem 0rem 1.5rem 0rem;
`;

const MainTitle = styled.h1`
	text-transform: uppercase;
	text-align: center;
	font-size: 3.5em;
`;

const SectionTitle = styled.h2`
	text-transform: uppercase;
	text-align: left;
	font-size: 1.2em;
`;

const ViewAll = styled.h3`
	text-transform: uppercase;
	text-align: center;
	font-size: 1.3em;
	text-decoration: underline;
	margin: auto;
	padding-top: 1.2rem;
`;

const Portfolio = () => {

	const itemsOnPortfolio = ['Portraits', 'Acting', 'Dance'];
	const {viewingDevice, windowHeight, windowWidth} = useContext(ViewingDeviceContext);
	// const [isViewerOpen, setIsViewerOpen] = useState(false);

	// const openImageViewer = useCallback((index) => {
	// 	setCurrentImage(index);
	// 	setIsViewerOpen(true);
	// }, []);

	const Image = styled.img`
		max-width: ${viewingDevice === ViewingDeviceViews.MOB? '100%' : '98%'};
	`;

	const MainImage = styled.div`
		min-height: ${windowHeight + 'px'};
		margin-top: -1rem;
		background-image: url("/images/homePage_shreya.jpg");
		background-position: center;
	  	background-repeat: no-repeat;
	  	background-size: cover;
	  	background-attachment: fixed;
	  	background-position-y: initial;
	`;

	return(
		<>
			<MainImage/>
			<PageContainer style={{top: 'unset',fontFamily: 'Open Sans', fontSize: '16px'}}>
				<MainTitle>Discover Shreya</MainTitle>

				{itemsOnPortfolio.map((item)=>{
					return(
						<SectionWrapper>
							<SectionTitle>{item}</SectionTitle>
							<Grid container justify="center" spacing={1} alignItems="center" >
								<ImageContainer item xs={12} sm={12} md={6} style={{textAlign: 'initial'}}>
									<Image alt={`Shreya-${item}-Image1`} src={`/images/Portfolio/${item}1.jpg`}/>
								</ImageContainer>
								<ImageContainer item xs={12} sm={12} md={6} style={{textAlign: 'end'}}>
									<Image alt={`Shreya-${item}-Image2`} src={`/images/Portfolio/${item}2.jpg`}/>
								</ImageContainer>
								<ViewAll>
									<Link to={`/portfolio/${item.toLowerCase()}`}>View All {item}</Link>
								</ViewAll>
							</Grid>
						</SectionWrapper>
					)
				})}
			</PageContainer>
		</>
	);
}

export default Portfolio;
import React from 'react';
import './App.css';
import { BrowserRouter,Route } from 'react-router-dom';
import Container from '@mui/material/Container';
import VideoLinksContextProvider from './contexts/VideoLinksContext';
import ViewingDeviceContextProvider from './contexts/ViewingDeviceContext';
import Home from './Home';
import About from './aboutPage/About';
import Contact from './contactPage/Contact';
import Portfolio from './portfolio/Portfolio';
import Portrait from './portfolio/Portrait';
import Project from './portfolio/Project';
import Acting from './portfolio/Acting';
import Dance from './portfolio/Dance';
import Vocals from './portfolio/Vocals';
import Instruments from './portfolio/Instruments';
import Pageantry from './portfolio/Pageantry';
import Sports from './portfolio/Sports';
import Header from './header/Header';
// import ThemeProvider from 'styled-components';

function App() {
	return(
			// <Container style={{height:'100%', paddingLeft:0, paddingRight:0, fontFamily: 'Open Sans', fontSize: '16px', margin: 'auto'}}>
				<BrowserRouter>
					<ViewingDeviceContextProvider>
						<VideoLinksContextProvider>
							<Header />
							<div className = "App" style={{margin: '-0.5rem'}}>
								<Route exact path='/' component={Home}/>
								<Route exact path='/about' component={About}/>
								<Route exact path='/contact' component={Contact}/>
								<Route exact path='/portfolio' component={Portfolio}/>
								<Route exact path='/portfolio/portraits' component={Portrait}/>
								<Route exact path='/portfolio/projects' component={Project}/>
								<Route exact path='/portfolio/acting' component={Acting}/>
								<Route exact path='/portfolio/dance' component={Dance}/>
								<Route exact path='/portfolio/vocals' component={Vocals}/>
								<Route exact path='/portfolio/instruments' component={Instruments}/>
								<Route exact path='/portfolio/pageantry' component={Pageantry}/>
								<Route exact path='/portfolio/sports' component={Sports}/>
							</div>
						</VideoLinksContextProvider>
					</ViewingDeviceContextProvider>
				</BrowserRouter>
			// </Container>
	);
}

export default App;
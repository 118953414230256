import React, {useState} from 'react';
import PageContainer from '../components/PageContainer'
import MainTitle from '../components/MainTitle';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { send } from 'emailjs-com';
// import AnimatedAlert from '../components/AnimatedAlert';
import Alert from '@mui/material/Alert';

const InfoWrapper = styled.div`
	margin-bottom: 1.5rem;
`;

const SectionWrapper = styled.div`
	margin: 1.5rem;
`;

const colabTopics = [
	{value: 'acting',label: 'Acting'},
	{value: 'dance',label: 'Dance'},
	{value: 'instruments',label: 'Instruments'},
	{value: 'pageantry',label: 'Pageantry'},
	{value: 'sports',label: 'Sports'},
	{value: 'vocals',label: 'Vocals'},
	{value: 'modelling',label: 'Modelling'}
];

const Contact = () => {

	const servideId = process.env.REACT_APP_EMAIL_SERVICE_ID;
	const templateId = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
	const userId = process.env.REACT_APP_EMAIL_SERVICE_USER_ID;

	const [formData, setFormData] = useState({
		contactName:'',email:'',message:'',collabType:''
	});
	const [successAlert, setSuccessAlert] = useState(false);
	const [failureAlert, setFailureAlert] = useState(false);

	const handleSubmit = (e) => {
		console.log("Submit clicked");
		e.preventDefault();
		setFormData({contactName:'',email:'',message:'',collabType:''});

		// setSuccessAlert(true);
		// setFailureAlert(true);
			
		send(servideId,templateId,formData,userId)		
	      .then((response) => {
	        console.log('SUCCESS!', response.status, response.text);
	        setFormData({contactName:'',email:'',message:'',collabType:''});
	      })
	      .catch((err) => {
	        console.log('FAILED...', err);
	      });

	 	setTimeout(
		    function() {
		        setSuccessAlert(false);
		        setFailureAlert(false);
		    }
		    .bind(this),
		    3000
		);
	}

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	}

	return(
		<PageContainer>
			{successAlert ? 
				<Alert severity="success" style={{display: 'inline-flex', position:'fixed', right:'1rem', animation: 'fadein 1s ease-in, fadeout 2s ease-in 4s', animationFillMode: 'forwards'}}>
					Collab request submitted successfully!
				</Alert> : ''
			}
			{failureAlert ? 
				<Alert severity="warning" style={{display: 'inline-flex', position:'fixed', right:'1rem', animation: 'fadein 1s ease-in, fadeout 2s ease-in 3s', animationFillMode: 'forwards'}}>
					Collab request failed! Please try again.
				</Alert> : ''
			}

			<Grid container justify="center" spacing={2} alignItems="center" >
				<Grid item xs={12} sm={12} md={6} style={{textAlign: 'center'}}>
					<p style={{textAlign: 'justify'}}>
						<SectionWrapper>
							<MainTitle title="Collaborate" description="Fill in to collaborate" textAlign="initial" descAlign="initial"/>
						</SectionWrapper>
						<SectionWrapper>
							<form onSubmit={handleSubmit} >
								<InfoWrapper>
									<TextField
										fullWidth required
										autoFocus={true}
										id="name"
										name="contactName"
										label="Name"
										defaultValue=""
										variant="filled"
										color="info"
										value={formData.contactName}
										onChange={handleChange}
							        />
								</InfoWrapper>
								<InfoWrapper>
									<TextField
										fullWidth required
										autoFocus={false}
										id="email"
										name="email"
										label="Email"
										defaultValue=""
										variant="filled"
										color="info"
										// value={email}
										// onInput={ e=>setEmail(e.target.value)}
										value={formData.email}
										onChange={handleChange}
							        />
								</InfoWrapper>
								<InfoWrapper>
									<TextField
										fullWidth required multiline
										autoFocus={false}
										rows={4}
										id="message"
										name="message"
										label="Message"
										defaultValue=""
										variant="filled"
										color="info"
										// value={message}
										// onInput={ e=>setMessage(e.target.value)}
										value={formData.message}
										onChange={handleChange}
							        />
								</InfoWrapper>
								<InfoWrapper>
									<TextField
										fullWidth required select
										autoFocus={false}
										id="collabType"
										name="collabType"
										label="Type of Booking"
										defaultValue=""
										variant="filled"
										color="info"
										// value={collabType}
										// onChange={ e=>setCollabType(e.target.value)}
										value={formData.collabType}
										onChange={handleChange}
							        >
								        {colabTopics.map((option) => (
								            <MenuItem key={option.value} value={option.value}>
								              {option.label}
								            </MenuItem>
								        ))}
							        </TextField>
								</InfoWrapper>
								<InfoWrapper style={{float:'right'}}>
									<Button 
										disabled={formData.contactName && formData.email && formData.message && formData.collabType ? false : true}
										type="submit"
										variant="contained"
										color="inherit"
										size="large"
										onClick={handleSubmit}
										sx={{backgroundColor:"#222", color: "#ddd"}}
									>
										Submit
									</Button>
								</InfoWrapper>

							</form>
						</SectionWrapper>
					</p>
				</Grid>
				<Grid item md={6} lg={6} sx={{textAlign: 'center', display: { xs: 'none', sm: 'none', md: 'block'}}}>
					<img alt="ContactPageImage" src="/images/aboutPage_shreya.jpg" style={{maxHeight: '40rem'}}/>
				</Grid>
			</Grid>
		</PageContainer>
	);
}

export default Contact;
import React,{useContext} from 'react';
import {ViewingDeviceContext} from '../contexts/ViewingDeviceContext'
import {ViewingDeviceViews} from '../utils.js'
import MobileNavBar from './MobileNavBar';
import DesktopNavBar from './DesktopNavBar';


const Header = () => {
	const {viewingDevice} = useContext(ViewingDeviceContext);
	console.log("viewingDevice in Header: ",viewingDevice);

	switch(viewingDevice){
		case ViewingDeviceViews.DESK: return (<DesktopNavBar/>);
		case ViewingDeviceViews.MOB: return (<MobileNavBar/> );
		default: return (<DesktopNavBar/>);
	}
}

export default Header;
import React, {useEffect, useState, useCallback,useContext} from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageViewer from "react-simple-image-viewer";
import './ComponentStyles.css';
import {/*resizeFile,*/isCellForVideo} from '../utils';
// import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ReactPlayer from 'react-player/youtube'
import {ViewingDeviceContext} from '../contexts/ViewingDeviceContext'
import {ViewingDeviceViews} from '../utils.js'

const ImageGrid = (
	{
		itemData,
		columns,
		videoLinkList,
		 ...props
	}) => {
	const {viewingDevice} = useContext(ViewingDeviceContext);
	const [currentImage, setCurrentImage] = useState(0);
	const [isViewerOpen, setIsViewerOpen] = useState(false);
	const [imageList, setImageList] = useState([]);
	const [loading,setLoading] = useState(true);
	const [totalItems,setTotalItems] = useState();

	// console.log("totalItems: ",totalItems);

	// const [resizedImageData, setResizedImageData] = useState();

	const openImageViewer = useCallback((index) => {
		setCurrentImage(index);
		setIsViewerOpen(true);
	}, []);

	// const resize = async (file) => {
	//   try {
	//     // const file = event.target.files[0];
	//     console.log("file: ",file);
	//     const image = await resizeFile(file);
	//     console.log(image);
	//     return image;
	//   } catch (err) {
	//     console.log(err);
	//   }
	// };

	useEffect(() => {
		if(itemData){
			setTotalItems(videoLinkList.length + Object.keys(itemData).length);
		}
		
		// console.log("resized Image: ");
		// itemData && itemData.forEach(function (item, index) {
		// 	list.push(item.src);
		// 	// const temp =  resize(item.src);
		// 	// setResizedImageData(resize(item.src));
		// });

		let list = [];
		let ic=-1, vc=-1;

		[...Array(totalItems)].map((x,index) => {
			// console.log("Index: ",index);
			const vCell = isCellForVideo(index,columns,videoLinkList);
			vCell ? vc++ : ic++;
			const item = itemData && itemData[ic];
			if (vCell) console.log("Index: ",index," is a videocell");

			videoLinkList && videoLinkList[vc] && vCell ? 
				list.push('video') : item && list.push(item.src)
		});

		// console.log("Image list: ", list);
		setImageList(list);

		if(itemData && itemData.length > 0) setLoading(false);
	}, [itemData,totalItems,videoLinkList]);



	// if(!resizedImageData || resizedImageData.length < 1){
	// 	return(<h1>No Images found</h1>);
	// }

	if(loading ){
		// return(<h1>No Images found</h1>);
		return (
		    <Box sx={{ display: 'flex' }}>
		      <CircularProgress sx={{margin: 'auto'}}/>
		    </Box>
		  );
	}

	const closeImageViewer = () => {
		setCurrentImage(0);
		setIsViewerOpen(false);
	};
	if(viewingDevice === ViewingDeviceViews.MOB){
		columns = 1;
	}

	let imageCounter=-1, videoCounter=-1;

	return(
		<>
			<ImageList sx={{overflow: 'inherit', ...props }}
				 variant="woven"
				 cols={columns ? columns : 3}
				 gap={viewingDevice === ViewingDeviceViews.MOB ? 0: 30}
			 >
		      {
		      	[...Array(totalItems)].map((x,index) => {
		   	      		
		      		const vCell = isCellForVideo(index,columns,videoLinkList);
		      		vCell ? videoCounter++ : imageCounter++;
		      		const item = itemData[imageCounter];

			      	return(
			      		videoLinkList && videoLinkList[videoCounter] && vCell ? 
			      		<>
			      		<ReactPlayer 
				      		url={videoLinkList[videoCounter]}
							playing={false}
							loop={false}
							controls={true}
							light={true}
							width='inherit'
							height='inherit'
						/>
				        </>:
				         <ImageListItem key={item && item.src}>
				          <img
				            src={`${item && item.src}?w=161&fit=crop&auto=format`}
		    				srcSet={`${item && item.src}?w=161&fit=crop&auto=format&dpr=2 2x`}
				            alt={item && item.title}
				            loading="lazy"
				            onClick={() => openImageViewer(index)}
				            className='imageHover'
				            style={
				            	{transition: 'all .2s ease-in-out'
				            }}
				          />
				        </ImageListItem>
				    )			    	
			    }	)
			 }
		    </ImageList>

		    {isViewerOpen && (
		        <ImageViewer
		          src={imageList}
		          currentIndex={currentImage}
		          onClose={closeImageViewer}
		          disableScroll={false}
		          backgroundStyle={{
		            backgroundColor: "rgba(0,0,0,0.9)",
		            zIndex:200
		          }}
		          
		          closeOnClickOutside={true}
		        />
		     )}
	    </>
	);
}


ImageGrid.defaultProps = {
  columns: 3,
  videoLinkList: []
}


export default ImageGrid;
import React, {useEffect, useState, useContext} from 'react';
import ImagePage from '../components/ImagePage';
import {importAll, generateImageList} from '../utils.js';
import {VideoLinksContext} from '../contexts/VideoLinksContext';
import {TextData,NumberOfColumns} from '../configData.js';

const Pageantry = () => {
	const {videoLinks} =  useContext(VideoLinksContext);
	const [itemData, setItemData] = useState();

	useEffect (() => {
		if((itemData === undefined || itemData.length < 1)){
			let images = importAll(require.context('../../public/images/Pageantry/', false, /\.(png|jpe?g|svg|JPE?G)$/));
			const imageList = generateImageList(images);
			setItemData(imageList);
		}
	},[itemData]);

	const desc = TextData.descriptions.PageantryPage ? TextData.descriptions.PageantryPage : ''; 
	return(
		<ImagePage
			itemData={itemData}
			title="Pageantry"
			description={desc}
			columns={NumberOfColumns.Pageantry}
			videoLinkList={videoLinks && videoLinks.Pageantry}
		/>
	);
}

export default Pageantry;
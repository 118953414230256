import Resizer from "react-image-file-resizer";

export const ViewingDeviceViews = Object.freeze({
    MOB: "MobileView",
    DESK: "DesktopView"
});

export const resizeFile = (file) => {
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,300,300,"JPG",100,0,
      (uri) => { 
      	resolve(uri);
      	return uri;
      },"base64"
    );
  });
}

export const importAll = (r) => {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); return});
  return images;
}

export const generateImageList = (images) => {
	let imageList = [];
	Object.keys(images).forEach((key, index) => {
	    imageList.push({"src":`${images[key].default}`, "title":`${(images[key].default.split('/')[3]).split('.')[0]}`})
	});
	// console.log("imageList: ");
	// console.log(imageList);
	return imageList;
}

const isCellDesignatedForVideo = (index,columns) => {
	let desForVid;
	switch(columns){
		case 3: desForVid = index%3 === 1 ? true : false;break;
		case 2: desForVid = index%2 === 0 ? true : false;break;
		default: desForVid = false; break
	}
	// console.log("desForVid: ", desForVid);
	return desForVid
}

export const isCellForVideo = (index,columns,videoLinkList) => {
	const linkNum = Math.floor(index/columns);
	// console.log("Index: ",index,"; columns: ",columns, "; linkNum: ",linkNum)
	const cellForVid = (videoLinkList[linkNum] && isCellDesignatedForVideo(index,columns)) ? true : false;
	// console.log("cellForVid: ", cellForVid);
	return cellForVid
}

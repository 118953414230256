import React, {useState} from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from 'react-router-dom';

const SelectEntry = styled(Link)({
  color: 'inherit',
  textDecoration: 'inherit',
});

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    // marginTop: theme.spacing(1),
    minWidth: '8rem',
    color: '#ddd',
    backgroundColor: '#222',
    position:'absolute',
    top: '6rem !important',
    // boxShadow:
    //   'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    // '& .MuiMenu-list': {
    //   padding: '4px 0',
    // },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        // fontSize: 18,
        // color: theme.palette.text.secondary,

      },
      // '&:active': {
      //   backgroundColor: alpha(
      //     theme.palette.primary.main,
      //     theme.palette.action.selectedOpacity,
      //   ),
      // },
    },
  },
}));


const ActivityDropdown = (
{
    setTabValue
}) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setTabValue(0);
    setAnchorEl(null);
  };

  return (
    <div style={{position:'relative', top:'0.35rem', fontWeight:'bold'}}>
      <Button
        id="demo-customized-button"
        aria-controls="demo-customized-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        color="inherit"
        sx={{fontFamily:'inherit', fontWeight: 'inherit', fontSize: 'inherit'}}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        // disableScrollLock={false}
      >
        Portfolio
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        variant={"menu"}
        getcontentanchorel= {null}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disableRipple>
          <SelectEntry to="/portfolio/portraits">Portraits</SelectEntry>
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <SelectEntry to="/portfolio/acting">Acting</SelectEntry>
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <SelectEntry to="/portfolio/dance">Dance</SelectEntry>
        </MenuItem>
        {/*<MenuItem onClick={handleClose} disableRipple>
          <SelectEntry to="/portfolio/vocals">Vocals</SelectEntry>
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <SelectEntry to="/portfolio/instruments">Instruments</SelectEntry>
        </MenuItem>*/}
        <MenuItem onClick={handleClose} disableRipple>
          <SelectEntry to="/portfolio/pageantry">Pageantry</SelectEntry>
        </MenuItem>
        {/*<MenuItem onClick={handleClose} disableRipple>
          <SelectEntry to="/portfolio/sports">Sports</SelectEntry>
        </MenuItem>*/}
      </StyledMenu>
    </div>
  );
}

export default ActivityDropdown;
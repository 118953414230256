import React, {useContext} from 'react';
import styled from 'styled-components'
import './ComponentStyles.css';
import {ViewingDeviceContext} from '../contexts/ViewingDeviceContext'
import {ViewingDeviceViews} from '../utils.js'

const MainTitle = ({
	title,
	description,
	textAlign,
	descAlign,
	...props
}) => {
	const {viewingDevice} = useContext(ViewingDeviceContext);

	const Title = styled.h1`
		font-family: Open Sans;
		text-transform: uppercase;
		text-align: ${textAlign ? textAlign : 'center'};
		font-size: ${viewingDevice === ViewingDeviceViews.MOB ? '2.8em' : '3.2em'};
		letter-spacing: 1px;
	`;

	const Desc = styled.div`
		max-width: 40rem;
	    overflow-wrap: break-word;
		text-align: ${descAlign ? descAlign : 'center'};
	    color: rgba(0,0,0,0.60);
	    margin: auto;
	    position: relative;
	    top: -2rem;
	    animation: fadein 2s;
	    font-family: NexaBold;
	    letter-spacing: 1.6px;
	    line-height: 25px;
	    font-size: large;
	    padding-left: 4px;
	`;

	return(
		<div>
			<Title>
				{title}
			</Title>
			<Desc>
				{description}
			</Desc>
		</div>
	)
};

export default MainTitle;
import React from 'react';
// import AboutShreyaImage from '../public/images/temp1.png';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';
import PageContainer from '../components/PageContainer'
import MainTitle from '../components/MainTitle';
import {TextData} from '../configData.js';


const SectionWrapper = styled.div`
	margin: 1.5rem;
`;

const Description = styled.div`
	
`;


const About = () => {
	return(
		<PageContainer style={{top: 'unset'}}>
			<Grid container justify="center" spacing={2} alignItems="center" >
				<Grid item sx={{ textAlign: 'center', display: { md: 'none', lg: 'none', xl: 'none'} }} xs={12} sm={12} style={{textAlign: 'center'}}>
					<img alt="AboutPageMobileImage" src="/images/aboutPage_shreya.jpg" style={{maxHeight: '40rem', width: '-webkit-fill-available'}}/>
				</Grid>
				<Grid item xs={12} sm={12} md={6} style={{textAlign: 'center'}}>
					<p style={{textAlign: 'justify'}}>
						<SectionWrapper>
							<MainTitle title="About Me" textAlign="initial"/>
						</SectionWrapper>

						<SectionWrapper>
							<Description>
								{TextData.about.aboutMe1}
							</Description>
							<Description>
								{TextData.about.aboutMe2}
							</Description>
						</SectionWrapper>
					</p>
				</Grid>
				<Grid item md={6} sx={{ textAlign: 'center', display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block'} }}>
					<img alt="AboutPageDesktopImage" src="/images/aboutPage_shreya.jpg" style={{maxHeight: '40rem'}}/>
				</Grid>
			</Grid>
		</PageContainer>
	);
}

export default About;
import React, {useEffect, useState, useContext} from 'react';
import ImagePage from '../components/ImagePage';
import {importAll, generateImageList} from '../utils.js';
import {VideoLinksContext} from '../contexts/VideoLinksContext';
import {TextData,NumberOfColumns} from '../configData.js';

const Project = () => {
	const {videoLinks} =  useContext(VideoLinksContext);
	console.log("videoLinks in projects: ",videoLinks);
	const [itemData, setItemData] = useState();

	useEffect (() => {
		if((itemData === undefined || itemData.length < 1)){
			let images = importAll(require.context('../../public/images/Projects/', false, /\.(png|jpe?g|svg|JPE?G)$/));
			// let images = importAll(require.context('../assets/images/Projects/', false, /\.(png|jpe?g|svg|JPE?G)$/));
			const imageList = generateImageList(images);
			setItemData(imageList);
		}
	},[itemData]);

	const desc = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi placerat lobortis ipsum eu fermentum. Nullam posuere maximus massa, vitae dignissim purus elementum ut. Donec ex lectus, sagittis non pulvinar quis, dictum elementum felis. Vivamus est nisi, ullamcorper non velit eget, ultricies venenatis diam. Aenean tristique sollicitudin sapien ac elementum. Pellentesque sagittis fermentum gravida. Donec ac tellus augue. Duis laoreet tortor eu mi consectetur, id egestas arcu commodo. Sed volutpat sem ac mi placerat accumsan. Mauris cursus tincidunt erat quis placerat." 
	return(
		<ImagePage
			itemData={itemData}
			title="Projects"
			description={desc}
			columns={NumberOfColumns.Projects}
			videoLinkList={videoLinks && videoLinks.projects}
		/>
	);
}

export default Project;
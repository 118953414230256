import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// const App = () => {
// 	return(
// 		<>
// 			<div>
// 				Hi There!
// 			</div>
// 		</>
// 	)
// }

ReactDOM.render(
	<App />,
	document.querySelector('#root')
)
import React from 'react';
import Logo from './Logo'
import NavTabs from './NavTabs'
import SocialMedia from './SocialMedia'
import Grid from '@mui/material/Grid'
import styled from 'styled-components'

const DummyDiv = styled.div`
	height: 7rem;
	width: auto;
`;

const DesktopNavBar = () => {
	console.log("DesktopNavBar");
	return(
		<>
			<DummyDiv/>
			<Grid id="mainNavBar" container justify="center" align="center" spacing={2} direction="row" 
				sx={
					{paddingTop: '1.5rem',
					backgroundColor: '#222',
					opacity: '1',
					position:'fixed', 
					top:0, 
					left:0, 
					zIndex:'100',
					fontFamily: 'inherit',
					color: '#ddd'
				}}>
				<Grid item xs={3} sm={3} md={3} style={{textAlign: 'initial', paddingLeft: '2rem'}}>
					<Logo />
				</Grid>
				<Grid alignItems="center"
	justify="flex-end" item xs={6} sm={6} md={6}>
					<NavTabs />
				</Grid>
				<Grid item xs={3} sm={3} md={3}>
					<SocialMedia />
				</Grid>
			</Grid>
		</>
	);
}

export default DesktopNavBar;
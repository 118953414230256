import React, {useEffect, useState, useContext} from 'react';
import ImagePage from '../components/ImagePage';
import {importAll, generateImageList} from '../utils.js';
import {VideoLinksContext} from '../contexts/VideoLinksContext';
import {TextData,NumberOfColumns} from '../configData.js';

const Vocals = () => {
	const {videoLinks} =  useContext(VideoLinksContext);
	const [itemData, setItemData] = useState();

	useEffect (() => {
		if((itemData === undefined || itemData.length < 1)){
			let images = importAll(require.context('../../public/images/Vocals/', false, /\.(png|jpe?g|svg|JPE?G)$/));
			const imageList = generateImageList(images);
			setItemData(imageList);
		}
	},[itemData]);

	const desc = TextData.descriptions.VocalsPage ? TextData.descriptions.VocalsPage : ''; 
	return(
		<ImagePage
			itemData={itemData}
			title="Vocals"
			description={desc}
			columns={NumberOfColumns.Vocals}
			videoLinkList={videoLinks && videoLinks.Vocals}
		/>
	);
}

export default Vocals;
import React from 'react';
import PageContainer from '../components/PageContainer'
import ImageGrid from '../components/ImageGrid';
import MainTitle from '../components/MainTitle';
// import {resizeFile} from '../utils';

const ImagePage = (
{
	itemData,
	title,
	description,
	columns,
	videoLinkList,
	...props
}) => {

	// useEffect(() => {
	// 	const resizedFile = 

	// },[itemData]);

	return(
		<PageContainer style={{}}>
			<MainTitle
				title={title}
				description={description}
			/>
			<ImageGrid
				itemData={itemData}
				columns={columns}
				videoLinkList={videoLinkList}
			/>
			
		</PageContainer>
	);
}

export default ImagePage;
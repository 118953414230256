import React, {useEffect, useState, useContext} from 'react';
import ImagePage from '../components/ImagePage';
import {importAll, generateImageList} from '../utils.js';
import {VideoLinksContext} from '../contexts/VideoLinksContext';
import {TextData,NumberOfColumns} from '../configData.js';

const Dance = () => {
	const {videoLinks} =  useContext(VideoLinksContext);
	const [itemData, setItemData] = useState();

	useEffect (() => {
		if((itemData === undefined || itemData.length < 1)){
			let images = importAll(require.context('../../public/images/Dance/', false, /\.(png|jpe?g|svg|JPE?G)$/));
			const imageList = generateImageList(images);
			setItemData(imageList);
		}
	},[itemData]);

	const desc = TextData.descriptions.DancePage ? TextData.descriptions.DancePage : ''; 
	return(
		<ImagePage
			itemData={itemData}
			title="Dance"
			description={desc}
			columns={NumberOfColumns.Dance}
			videoLinkList={videoLinks && videoLinks.Dance}
		/>
	);
}

export default Dance;
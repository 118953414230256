import React from 'react';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';
import { Facebook, Twitter, Instagram } from '@mui/icons-material';
import styled from 'styled-components'

const IconWrapper = styled.span`
	padding-right: 1rem;
	height: 1.5rem;
`;

const SocialMedia = () => {
	return(
		<>
			<span style={{float: 'right', paddingTop: '0.75rem', paddingRight: '1rem'}}>
				<IconWrapper>
					<a  style={{color:'inherit'}} href="https://www.imdb.com/name/nm11380062/" rel="noreferrer" target="_blank">
						<img alt="IMDBLogo" src="/images/imdbLogo.png" style={{height:'1.5rem'}}/>
					</a>
				</IconWrapper>
				<IconWrapper>
					<a style={{color:'inherit'}} href="https://www.facebook.com/shreya.nagesh.1/" rel="noreferrer" target="_blank">
						<Facebook/>
					</a>
				</IconWrapper>
				<IconWrapper>
					<a style={{color:'inherit'}} href="https://twitter.com/shrezam?lang=en" rel="noreferrer" target="_blank">
						<Twitter/>
					</a>
				</IconWrapper>
				<IconWrapper>
					<a style={{color:'inherit'}} href="https://www.instagram.com/crazymiss_san/?hl=en" rel="noreferrer" target="_blank">
						<Instagram/>
					</a>
				</IconWrapper>
			</span>
		</>
	);
}

export default SocialMedia;
import React, { useState, useEffect, createContext} from 'react';

export const VideoLinksContext = createContext();

const VideoLinksContextProvider = (props) => {
	const [videoLinks,setVideoLinks] = useState();

	useEffect (() => {
		fetch(`${process.env.PUBLIC_URL}/data/videoLinks.json`,{
	      headers : { 
	        'Content-Type': 'application/json',
	        'Accept': 'application/json'
	       }

	    })
			.then((r) => r.json())
			.then((data) =>{
				console.log("videoLinks in context: ");
				console.log(data);
    			setVideoLinks(data);
    		});
	},[]);

	return(
		<VideoLinksContext.Provider value={{videoLinks}}>
			{props.children}
		</VideoLinksContext.Provider>
	);
}

export default VideoLinksContextProvider;

import React from 'react';
import { Link } from 'react-router-dom';

const Logo = () => {
	return(
		<>
			<span>
				<Link to="/">
					<img alt="Logo" src="/images/logo.png" 
						style={{maxHeight: '5rem', position: 'relative', top: '-1rem'}}/>
				</Link>
			</span>
		</>
	);
}

export default Logo;